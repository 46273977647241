// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../resources/main-bg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fighters___root {\r\n  display: flex;\r\n  flex-direction: column;\r\n  width: 100%;\r\n  height: 100%;\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  background-size: cover;\r\n  background-repeat: no-repeat;\r\n  background-position: center;\r\n}\r\n\r\n.fighters___list {\r\n  margin: 40px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\n.fighters___fighter {\r\n  height: 150px;\r\n  max-width: 150px;\r\n  flex: 1;\r\n  display: flex;\r\n  justify-content: center;\r\n  cursor: pointer;\r\n  background: #ffdfa7;\r\n  margin: 10px;\r\n  border: 5px solid #ca9650;\r\n}\r\n\r\n.fighters___fighter:hover {\r\n  background: #cbe4f8;\r\n  border: 5px solid #267abf;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/styles/fighters.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,yDAAoD;EACpD,sBAAsB;EACtB,4BAA4B;EAC5B,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,OAAO;EACP,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,mBAAmB;EACnB,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B","sourcesContent":[".fighters___root {\r\n  display: flex;\r\n  flex-direction: column;\r\n  width: 100%;\r\n  height: 100%;\r\n  background-image: url('../../resources/main-bg.jpg');\r\n  background-size: cover;\r\n  background-repeat: no-repeat;\r\n  background-position: center;\r\n}\r\n\r\n.fighters___list {\r\n  margin: 40px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\n.fighters___fighter {\r\n  height: 150px;\r\n  max-width: 150px;\r\n  flex: 1;\r\n  display: flex;\r\n  justify-content: center;\r\n  cursor: pointer;\r\n  background: #ffdfa7;\r\n  margin: 10px;\r\n  border: 5px solid #ca9650;\r\n}\r\n\r\n.fighters___fighter:hover {\r\n  background: #cbe4f8;\r\n  border: 5px solid #267abf;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
