export const fighters = [
  {
    _id: '1',
    name: 'Ryu',
    source: 'https://media.giphy.com/media/kdHa4JvihB2gM/giphy.gif',
  },
  {
    _id: '2',
    name: 'Dhalsim',
    source: 'https://i.pinimg.com/originals/c0/53/f2/c053f2bce4d2375fee8741acfb35d44d.gif',
  },
  {
    _id: '3',
    name: 'Guile',
    source: 'https://66.media.tumblr.com/tumblr_lq8g3548bC1qd0wh3o1_400.gif',
  },
  {
    _id: '4',
    name: 'Zangief',
    source: 'https://media1.giphy.com/media/nlbIvY9K0jfAA/source.gif',
  },
  {
    _id: '5',
    name: 'Ken',
    source: 'https://i.pinimg.com/originals/46/4b/36/464b36a7aecd988e3c51e56a823dbedc.gif',
  },
  {
    _id: '6',
    name: 'Bison',
    source: 'http://www.fightersgeneration.com/np5/char/ssf2hd/bison-hdstance.gif',
  },
];

export const fightersDetails = [
  {
    _id: '1',
    name: 'Ryu',
    health: 45,
    attack: 4,
    defense: 3,
    source: 'https://media.giphy.com/media/kdHa4JvihB2gM/giphy.gif',
  },
  {
    _id: '2',
    name: 'Dhalsim',
    health: 60,
    attack: 3,
    defense: 1,
    source: 'https://i.pinimg.com/originals/c0/53/f2/c053f2bce4d2375fee8741acfb35d44d.gif',
  },
  {
    _id: '3',
    name: 'Guile',
    health: 45,
    attack: 4,
    defense: 3,
    source: 'https://66.media.tumblr.com/tumblr_lq8g3548bC1qd0wh3o1_400.gif',
  },
  {
    _id: '4',
    name: 'Zangief',
    health: 60,
    attack: 4,
    defense: 1,
    source: 'https://media1.giphy.com/media/nlbIvY9K0jfAA/source.gif',
  },
  {
    _id: '5',
    name: 'Ken',
    health: 45,
    attack: 3,
    defense: 4,
    source: 'https://i.pinimg.com/originals/46/4b/36/464b36a7aecd988e3c51e56a823dbedc.gif',
  },
  {
    _id: '6',
    name: 'Bison',
    health: 45,
    attack: 5,
    defense: 4,
    source: 'http://www.fightersgeneration.com/np5/char/ssf2hd/bison-hdstance.gif',
  },
];
